import React from "react";
import { StaticQuery, graphql } from "gatsby";

import { stack as Menu } from "react-burger-menu";
import Accordion from '../Accordion';
import Button from '../Button';

import styles from './navigation.module.scss';
import {getLinkTag, trackEvent} from "../../utils";

class MobileNavigation extends React.Component {
  render() {
    return(
      <StaticQuery
        query={navigationQuery}
        render={data => {
          const {menuItems, cta} = data.contentfulNavigation;

          return (
            <Menu {...this.props}>
              {menuItems.map(item => {
                if (item.subMenu) {
                  return (
                    <div className="menu-item" key={item.id}>
                      <Accordion variant="menu">
                        <Accordion.Item key={item.id} title={item.label}>
                          {item.subMenu.map(subItem => {
                            const linkInformation = getLinkTag(subItem.pathname);
                            const Tag = linkInformation.tag;
                            const tagProps = linkInformation.tagProps;

                            if (linkInformation.isExternalLink) {
                              tagProps.gtmEventName = "Navigation";
                              tagProps.label = subItem.label;
                            } else {
                              tagProps.onClick = function() {
                                trackEvent('Navigation', subItem.label)
                              }
                            }

                            return (
                              <Tag key={subItem.id} {...tagProps} className={styles.mobileSubItem}>
                                {subItem.label}
                              </Tag>
                            )
                          })}
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  )
                }
                const linkInformation = getLinkTag(item.pathname);
                const Tag = linkInformation.tag;
                const tagProps = linkInformation.tagProps;

                if (linkInformation.isExternalLink) {
                  tagProps.gtmEventName = "Navigation";
                  tagProps.label = item.label;
                } else {
                  tagProps.onClick = function() {
                    trackEvent('Navigation', item.label)
                  }
                }

                return (
                  <Tag key={item.id} {...tagProps} className={styles.mobileItem}>
                    {item.label}
                  </Tag>
                )
              })}

              {cta && (
                <Button link={cta.url}
                        variant="secondary"
                        isFull
                        modifierclass={styles.mobileBtn}
                        istargetblank={cta.istargetblank}
                        gtmEventName={cta.gtmEventName}
                        gtmEventType={cta.gtmEventType}>
                  {cta.label}
                  </Button>
              )}
            </Menu>
          )
        }}
      />
    )
  }
}

export default MobileNavigation;

const navigationQuery = graphql`
    query MobileNavigationQuery {
        contentfulNavigation {
            id,
            menuItems{
                id,
                label,
                pathname,
                subMenu{
                    id,
                    label,
                    pathname
                }
            },
            cta{
                label,
                url,
                istargetblank,
                gtmEventName,
                gtmEventType
            }
        }
    }
`;
