import React from 'react';
import { StaticQuery, graphql } from "gatsby";
import { Link } from 'gatsby';
import { Container } from 'react-grid-system';

import Button from '../Button';
import Dropdown from '../Dropdown';
import {trackEvent} from '../../utils';

import styles from './navigation.module.scss';

class Navigation extends React.Component {
  render() {
    return (
      <StaticQuery
        query={navigationQuery}
        render={data => {
          const {logoImage, menuItems, cta, userAreaUrl} = data.contentfulNavigation;
          return (
            <header className={styles.header}>
              <Container>
                <div className={styles.wrapper}>
                  <nav className={styles.nav}>
                    <div className={styles.logo}>
                      <Link to="/" onClick={trackEvent.bind(null, 'Navigation', 'Home', null)}>
                        <img src={logoImage.file.url} alt="Money2020 Asia"/>
                      </Link>
                    </div>

                    <div className={styles.menuWrap}>
                      <ul className={styles.list}>
                        {menuItems.map(item => (
                          <li key={item.id} className={styles.listItem}>
                            {item.pathname ?
                              <Link
                                to={item.pathname}
                                activeClassName="active"
                                className={styles.link}
                                onClick={trackEvent.bind(null, 'Navigation', item.label, null)}>
                                <span className="c-header__label">{item.label}</span>
                              </Link>
                              :
                              <Dropdown trackEvents={true} openDirection="left" options={item.subMenu}>{item.label}</Dropdown>
                            }
                          </li>
                        ))}
                      </ul>
                      {cta && (
                        <div className={styles.cta}>
                          <Button
                            link={cta.url}
                            size="small"
                            modifierclass={styles.desktopBtn}
                            istargetblank={cta.istargetblank}
                            gtmEventName={cta.gtmEventName}
                            gtmEventType={cta.gtmEventType}
                          >
                            {cta.label}
                          </Button>
                        </div>
                      )}

                      {userAreaUrl && (
                        <a href={userAreaUrl}
                          onClick={trackEvent.bind(null, 'Navigation', 'Account', null)}
                        >
                          <span className={styles.label}>Account link</span>
                          <div className={styles.account}></div>
                        </a>
                      )}
                    </div>
                  </nav>
                </div>
              </Container>
            </header>
          )
        }}
      />
    )
  }
}

export default Navigation;

const navigationQuery = graphql`
    query NavigationQuery {
        contentfulNavigation {
            id,
            logoImage {
                file {
                    url
                }
            },
            menuItems{
                id,
                label,
                pathname,
                subMenu{
                    id,
                    label,
                    pathname
                }
            },
            cta {
                label,
                url,
                istargetblank,
                gtmEventName,
                gtmEventType
            },
            userAreaUrl
        }
    }
`;
