import React from 'react';
import { Link } from 'gatsby'
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-grid-system';
import {withPrefix} from "gatsby";

import styles from './footer.module.scss';

function Footer() {
  const links = [
    {label: 'Copyright', url: '/copyright-terms'},
    {label: 'Privacy Policy', url: '/ascential-events-privacy-cookies-policy'},
    {label: 'All Terms & Conditions', url: '/our-terms-conditions'}
  ];

  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.footerPrimary}>
          <Row>
            <Col xs={12} md={12} lg={2.4} align-self='center'>
              <div className={styles.imgWrapper}>
                <Link to="/">
                  <img className={styles.image} src={withPrefix(`/images/M20E19-LOGO-FOOTER.svg`)} alt="Money2020 Europe" />
                </Link>
              </div>
            </Col>
            <Col xs={6} md={3} lg={2.4}>
              <ul className={styles.list}>
                <li className={styles.listItem}>
                  <Link className={styles.link} to="/agenda/#/agenda">Agenda</Link>
                </li>
                <li className={styles.listItem}>
                  <Link className={styles.link} to="/registration-pricing-listing">Attend</Link>
                </li>
                <li className={styles.listItem}>
                  <Link className={styles.link} to="/why-sponsor">Sponsor</Link>
                </li>
              </ul>
            </Col>
            <Col xs={6} md={3} lg={2.4}>
              <ul className={styles.list}>
                <li className={styles.listItem}>
                  <Link className={styles.link} to="/agenda/#/speakers">Speakers</Link>
                </li>
                <li className={styles.listItem}>
                  <Link className={styles.link} to="/sponsors/2019">Sponsors</Link>
                </li>
                <li className={styles.listItem}>
                  <Link className={styles.link} to="/press-resources">Press Resources</Link>
                </li>
              </ul>
            </Col>
            <Col xs={6} md={3} lg={2.4}>
              <ul className={styles.list}>
                <li className={styles.listItem}>
                  <Link className={styles.link} to="/rise">Rise Up</Link>
                </li>
                <li className={styles.listItem}>
                  <Link className={styles.link} to="/ewpn-annual-conference-money2020-europe">EWPN Conference</Link>
                </li>
                <li className={styles.listItem}>
                  <Link className={styles.link} to="/media-partners/2019">Media partners</Link>
                </li>
              </ul>
            </Col>
            <Col xs={6} md={3} lg={2.4}>
              <ul className={styles.list}>
                <li className={styles.listItem}>
                  <p className={styles.text}>MONEY20/20 EUROPE</p>
                  <p className={styles.text}>33 KINGSWAY</p>
                  <p className={styles.text}>LONDON WC2B 6UF</p>
                  <a href="mailto:europe@money2020.com" className={`${styles.text} ${styles.mail}`}>europe@money2020.com</a>
                  <a href="tel:00442030332070" className={styles.text}>+44 (0) 203 033 2070</a>
                </li>
              </ul>
            </Col>
            <Col xs={12}>
              <div className={styles.disclaimer}>*Based on attendee statistics from Money20/20 Europe 2018</div>
            </Col>
          </Row>
        </div>

        <div className={styles.footerSecondary}>
          <Row>
            <Col>
              {links.map((link, index) => (
                <Link className={styles.linkSecondary} to={link.url} key={index}>{link.label}</Link>
              ))}
            </Col>
          </Row>
        </div>
      </Container>
    </footer>
  );
}

Footer.propTypes = {
  title: PropTypes.string
};

Footer.defaultProps = {
};

export default Footer;
