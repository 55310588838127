import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './button.module.scss';
import {getLinkTag, trackEvent} from "../../utils";

let cx = classNames.bind(styles);

function Button({isFull, isMobileFull, ...props}) {
  let buttonClassName = cx({
    button: true,
    buttonPrimary: props.variant === 'primary',
    buttonSecondary: props.variant === 'secondary',
    buttonSmall: props.size === 'small',
    buttonLarge: props.size === 'large',
    buttonFull: isFull,
    buttonMobileFull: isMobileFull
  });

  let Tag = 'button';
  let tagProps = {};

  const linkInformation = getLinkTag(props.link);
  Tag = linkInformation.tag;
  tagProps = linkInformation.tagProps;

  if (props.gtmEventName) {
    const eventType = props.gtmEventType ? props.gtmEventType : 'Button';

    if (linkInformation.isExternalLink) {
      tagProps.gtmEventName = eventType;
      tagProps.label = props.gtmEventName;
    } else {
      tagProps.onClick = function() {
        trackEvent(eventType, props.gtmEventName)
      }
    }
  }



  if (props.istargetblank) {
    tagProps.target = "_blank";
    tagProps.rel = "noopener noreferrer";
  }

  if (props.modifierclass) {
    buttonClassName = `${buttonClassName} ${props.modifierclass}`
  }

  return (
    <Tag {...tagProps} className={buttonClassName} style={props.style}>
      {props.children}
    </Tag>
  );
}

Button.propTypes = {
  gtmEventName: PropTypes.string,
  isFull: PropTypes.bool,
  isMobileFull: PropTypes.bool,
  istargetblank: PropTypes.bool,
  link: PropTypes.string.isRequired,
  size: PropTypes.string,
  variant: PropTypes.string
};

Button.defaultProps = {
  isFull: false,
  isMobileFull: false,
  size: "regular",
  variant: "primary"
};

export default Button;
