import { Link } from 'gatsby';
import OutboundLink from '../components/OutboundLink';

export const getLinkTag = function(uri) {
  if (!uri) {
    return {
      tag: 'a',
      tagProps: {href: uri}
    }
  }
  const isExternalLink = uri.indexOf('http') >= 0;
  const Tag = isExternalLink ? OutboundLink : Link;
  const tagProps = isExternalLink ? {href: uri} : {to: uri};
  return ({
    isExternalLink: isExternalLink,
    tag: Tag,
    tagProps: tagProps
  });
};

export const trackEvent = function(category, action, label) {
  if (!window || !window.ga) {
    return;
  }

  const eventLabel = label ? label : window.location.pathname;

  // eslint-disable-next-line no-undef
  ga('send', 'event', category, action, eventLabel)
};