import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import styles from './accordion.module.scss';
import classNames from "classnames/bind";

let cx = classNames.bind(styles);

class Item extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
    this.mounted = true;
  }

  handleDocumentClick = event => {
    if (
      this.mounted &&
      !ReactDOM.findDOMNode(this).contains(event.target) &&
      this.state.isOpen
    ) {
      this.setState({isOpen: false});
    }
  };

  componentDidMount() {
    if (this.props.atomic) {
      document.addEventListener('click', this.handleDocumentClick, false);
      document.addEventListener('touchend', this.handleDocumentClick, false);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    document.removeEventListener('click', this.handleDocumentClick, false);
    document.removeEventListener('touchend', this.handleDocumentClick, false);
  }

  onClick = () => {
    this.setState({isOpen: !this.state.isOpen});
  };

  render() {
    const accordionItemClassNames = cx({
      item: true,
      itemActive: this.state.isOpen
    });

    return (
      <div className={accordionItemClassNames}>
        <button className={styles.title} onClick={this.onClick}>
          {this.props.title}
        </button>
        <div className={styles.container}>
          <div className={styles.content}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}

Item.propTypes = {
  atomic: PropTypes.bool,
  title: PropTypes.string
};

Item.defaultProps = {
  atomic: true,
  title: 'Accordion title'
};

export default Item;