import React from 'react';

import {getLinkTag, trackEvent} from '../../utils';

import styles from './dropdown.module.scss';

class Dropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDropdown: false,
      selectedIds: []
    };
  };

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.options !== nextProps.options
      || this.state.showDropdown !== nextState.showDropdown
      || this.state.selectedIds !== nextState.selectedIds;
  }

  handleDropdownOpen = () => {
    this.setState({showDropdown: true, selectedIds: []});
  };

  handleDropdownClose = () => {
    this.setState({showDropdown: false, selectedIds: []});
  };

  handleSelectedId = (selected, depthLevel) => {
    return () => {
      const updatedArray = this.state.selectedIds.slice(0);
      updatedArray[depthLevel] = selected;

      this.setState({selectedIds: updatedArray});
    };
  };

  renderSubMenu(options, depthLevel = 0) {
    if (this.state.showDropdown !== true) {
      return null;
    }

    const menuOptions = options.map(option => {
      const linkInformation = getLinkTag(option.pathname);
      const Tag = linkInformation.tag;
      const tagProps = linkInformation.tagProps;

      if (this.props.trackEvents && !linkInformation.isExternalLink) {
        tagProps.onClick = function() {
          trackEvent('Navigation', option.label)
        }
      }

      if (this.props.trackEvents && linkInformation.isExternalLink) {
        tagProps.gtmEventName = "Navigation";
        tagProps.label = option.label;
      }

      const display  = <Tag {...tagProps} className={styles.optionsLink}>{option.label}</Tag>;
      const hasOptions = option.options && option.options.length > 0;

      let subMenu;

      // only render selected submenu and only if nested options exist
      if (this.state.selectedIds[depthLevel] === option.id && hasOptions) {
        const newDepthLevel = depthLevel + 1;
        subMenu = this.renderSubMenu(option.options, newDepthLevel);
      }

      return (
        <li
          key={option.id}
          className={styles.optionsListItem}
          onMouseEnter={this.handleSelectedId(option.id, depthLevel)}
        >
          {display}
          {subMenu}
        </li>
      );
    });

    return (
      <div className={styles.options}>
        <ul className={styles.optionsList}>
          {menuOptions}
        </ul>
      </div>
    );
  }

  render() {
    return (
      <div
        className={styles.dropdown}
        onMouseEnter={this.handleDropdownOpen}
        onMouseLeave={this.handleDropdownClose}
      >
        <div className={styles.display}>
          {this.props.children}
        </div>
        {this.renderSubMenu(this.props.options)}
      </div>
    )
  }
}

export default Dropdown;