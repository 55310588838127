import React from "react";
import {withPrefix} from "gatsby";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

import MobileNavigation from '../components/Navigation/mobileNavigation';
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";

import { setConfiguration } from 'react-grid-system';

import "../sass/index.scss";

setConfiguration({gutterWidth: 32});

const Layout = ({ children }) => (
  <React.Fragment>
    <Helmet>
      <link rel="stylesheet" href="https://use.typekit.net/iex1rlv.css" />
      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.2/css/all.css" integrity="sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr" crossorigin="anonymous"/>
      <link rel="apple-touch-startup-image" href={withPrefix("splash/640x1136.png")} media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"/>
      <link rel="apple-touch-startup-image" href={withPrefix("splash/750x1294.png")} media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"/>
      <link rel="apple-touch-startup-image" href={withPrefix("splash/1242x2148.png")} media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"/>
      <link rel="apple-touch-startup-image" href={withPrefix("splash/1125x2436.png")} media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"/>
      <link rel="apple-touch-startup-image" href={withPrefix("splash/1536x2048.png")} media="(min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)"/>
      <link rel="apple-touch-startup-image" href={withPrefix("splash/1668x2224.png")} media="(min-device-width: 834px) and (max-device-width: 834px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)"/>
      <link rel="apple-touch-startup-image" href={withPrefix("splash/2048x2732.png")} media="(min-device-width: 1024px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)"/>
    </Helmet>
    <div id="page">
      <MobileNavigation pageWrapId={"page-wrap"} outerContainerId={"page"}  width={'100%'}/>
      <Navigation/>

      <div id="page-wrap">
        {children}
        <Footer />
      </div>
    </div>
  </React.Fragment>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout
