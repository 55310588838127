import React from 'react';
import PropTypes from 'prop-types';

import styles from './accordion.module.scss';
import classNames from "classnames/bind";

import Item from './item';

let cx = classNames.bind(styles);

class Accordion extends React.Component {
  static Item = Item;

  render() {
    const accordionClassNames = cx({
      accordion: true,
      accordionMenu: this.props.variant === 'menu'
    });

    return (
      <div className={accordionClassNames}>
        {React.Children.map(this.props.children, child => {
          if (!child) return;

          return (
            React.cloneElement(child, {
              atomic: this.props.atomic
            }))
          }
        )}
      </div>
    );
  }
}

Accordion.propTypes = {
  atomic: PropTypes.bool,
  variant: PropTypes.string
};

export default Accordion;